<template>
 <div>
     <div class="cash_header">
         <div class="header_item_wrapper" 
         v-for="(logo, i) in inda_logos" 
         :key="i"
         @click="checkDropdown(logo.name)"
         >
             <img class="logo" :src="logo.link" alt="coin_logo">
             <span class="header_item">{{ logo.name }}</span>
         </div>
         <button @click="goToIndacoin()"> {{$t('interface.credit_card')}}  </button>
     </div>
 </div>
</template>
<script>
import parameters from '@/assets/parameters.js'
import LocaleSwither from '@/components/LocaleSwither.vue' 
export default{
 name: 'CashHeader',
 components: {
    LocaleSwither
 },
 methods: {
  checkDropdown: function(name_img){
            if(name_img === 'Mastercard' || name_img === 'Visa' || name_img === 'Indacoin'){
                window.open('https://buy.pkoin.indacoin.io/', '_blank');
            } else{    
                const arr = parameters.currencies.filter(item => item.code === name_img);   
                this.currency = arr[0];
                console.log(this.currency);
                this.$store.commit('SET_SELECTED_CURRENCY', this.currency)                      
                
            }
            
        },
  goToIndacoin: function(){
      window.open('https://buy.pkoin.indacoin.io/')
  }

 },
 data(){
  return {
   inda_logos: parameters.indacoin_logo,
   logos: parameters.coin_logos,
  }
 }
  
}
</script>
<style lang="sass" scoped>
div     
 display: flex
 justify-content: center
 // flex-wrap: wrap
 // flex-wrap: wrap
 width: 100%
 .cash_header
     width: 100%
     display: flex
     justify-content: center
     align-items: center
     flex-wrap: wrap
     padding: 20px 0
     button
         width: 100%
         background: transparent
         border: none
         font-size: 1.1em
         // padding-top: 10px
         text-align: center
         color: #fff
         font-weight: 500
         cursor: pointer
         transition: .5s 
         &:hover            
             color: #2196F3
     


     .header_item_wrapper
         display: flex
         flex-direction: column
         align-items: center
         padding: $r
         width: 70px
         transition: transform .2s ease-in-out
         &:hover
             transform: scale(1.3)            

         .logo
             width: 100%
             // height: 35px
         .header_item
             font-size: 0.7em
             text-decoration: none
</style>