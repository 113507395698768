<template>
<div class="wrapper-press">
 <div class="logo-wrap">
  <div 
  v-for="(logo, i) in press_logo"
  class="item-img"
  :key="i"
  >
   <a :href="logo.site_link" target="_blanl">
    <img :src="logo.logo_link" alt="">
    <span>{{logo.publish_date}}</span>
   </a>
  </div>
 </div>

</div>

 
</template>

<script>
import parameters from '@/assets/parameters.js'
 export default{
  name: 'PressReleases',
  data() {
   return{
    press_logo: parameters.press
   }
 }
 }
</script>
<style lang="sass" scoped>
.wrapper-press
 width: 100%
 padding: 30px $r*20
 display: flex
 justify-content: center
 flex-wrap: wrap
 background: rgba(255, 255, 255, .3)
 .logo-wrap
  display: flex
  justify-content: space-around
  align-items: center
  flex-wrap: wrap
  .item-img
   width: 130px
   position: relative
   align-items: center
   margin: 15px   
   a
    text-decoration: none
    width: 100%
    img
     width: 130px
    span
     color: #fff
     font-weight: 600
     text-align: center
     text-decoration: none
    




</style>